import React from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import SectionHeader from "./SectionHeader";
import "./FeaturesSection.scss";

function FeaturesSection(props) {
  const items = [
    {
      title: "Write Function",
      description:
        "Write your Nodejs function in online editor. (Python coming soon)",
      iconClass: "fas fa-code",
      iconColor: "danger",
    },
    {
      title: "Deploy",
      description: "With one click deploy it to cloud and get REST API url.",
      iconClass: "fas fa-cloud",
      iconColor: "primary",
    },
    {
      title: "Call",
      description: "Call your cloud function from anywhere.",
      iconClass: "fas fa-cogs",
      iconColor: "success",
    },
  ];

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Row className="align-items-center">
          <Col lg={6} className="text-center text-lg-left feature-image">
            <figure className="FeaturesSection__image-container mx-auto">
              <Image src={props.image} fluid={true} />
            </figure>
          </Col>
          <Col className="offset-lg-1 mt-0 mt-lg-0 ">
            {items.map((item, index) => (
              <Row className="py-4 align-items-center" key={index}>
                <Col xs="auto">
                  <div
                    className={`FeaturesSection__icon text-${item.iconColor} d-inline-flex justify-content-center`}
                  >
                    <i className={`${item.iconClass}`} />
                  </div>
                </Col>
                <Col sx="auto" className="pl-4">
                  <SectionHeader
                    title={item.title}
                    subtitle={item.description}
                    size={5}
                    spaced={false}
                    className="mb-0"
                  />
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
