import React from "react";
import ContactSection from "../components/ContactSection";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

function AboutPage(props) {
  return (
    <>
    <div className="p-4">

        <div className="p-6 jumbotron">
            <div className="py-4">
                <a href="/">
                    <img className="d-inline-block align-top" src={"logo.png"} alt="Logo" height="50"/>
                </a>
            </div>
            {/*<h3 class="display-4">About Us!</h3>*/}
            <p className="lead">Tiny Function is developed by a tiny team as a side project. Lets see where it goes.</p>
            <hr className="my-4"/>
            <p>For more and latest information please visit FAQ Section.</p>
            <p className="lead">
                <a className="btn btn-primary btn-lg" href="#" role="button"
                    target="_blank" href="https://www.notion.so/TinyFunction-FAQ-Help-fb3f9210fb474d3288e5a93ef5d54d6a"
                >FAQ</a>
            </p>
        </div>


        { /*<div>
        <a href="/">
            <img className="d-inline-block align-top" src={"logo.png"} alt="Logo" height="50"/>
        </a>

            <h2> About Us </h2>
                Tiny Function is developed by a tiny team as a side project. Lets see where it goes.
                For more and latest information please visit <a target="_blank" href="https://www.notion.so/TinyFunction-FAQ-Help-fb3f9210fb474d3288e5a93ef5d54d6a">FAQ</a> section.
        </div>*/}

    </div>

      {/*<ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us/ Feature Request"
        subtitle=""
        buttonText="Send"
        buttonColor="primary"
        showNameField={true}
      /> */}
    </>
  );
}

export default AboutPage;
