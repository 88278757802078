import React from "react";
import "./../styles/global.scss";
import IndexPage from "./index";
import FaqPage from "./faq";
import AboutPage from "./about";
import PricingPage from "./pricing";
import { Switch, Route, Router } from "./../util/router.js";
import FirebaseActionPage from "./firebase-action.js";
import NotFoundPage from "./not-found.js";
import "./../util/analytics.js";
import { AuthProvider } from "./../util/auth.js";

function App(props) {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={IndexPage} />

          <Route exact path="/faq" component={FaqPage} />

          <Route exact path="/about" component={AboutPage} />

          <Route exact path="/pricing" component={PricingPage} />

          <Route exact path="/firebase-action" component={FirebaseActionPage} />

          <Route component={NotFoundPage} />
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
