import React, { useState } from "react";
import Section from "./Section";
import Container from "react-bootstrap/Container";
import SectionHeader from "./SectionHeader";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";

import { UnControlled as CodeMirror } from 'react-codemirror2'
//import {Controlled as CodeMirror} from 'react-codemirror2'
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
//import UrlDialog from './UrlDialog';
import UrlDialogBox from './UrlDialogBox';

const axios = require('axios')

function EditorSection(props) {

  const codea = `function add(a, b) {
    return a + b;
}
      `;

/*  let urltest = { url:'https://tinyfunction.com/call/asdfasdf', sample_url:'https://tinyfunction.com/call/asdfasdf?a=4&b=4' };
  const [returnUrl, setReturnUrl] = useState(urltest);
  const [modalIsOpen, setIsOpen] = useState(true);*/

  const [returnUrl, setReturnUrl] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isSpinner, setIsSpinner] = useState(false);
  const options = { mode: 'javascript', theme: 'material', lineNumbers: true }
  var codeString = codea;
 

  function onDeployButtonClick() {
    setReturnUrl('')
    setIsSpinner(true)
    let inputData = { sourceString: codeString };
    //var obj = { name: "John", age: 30, city: "New York" };
    axios.post('https://tinyfunction.com/deployFunction', inputData)
      .then(async (response) => {
        setIsSpinner(false)
        await openModal()
        if (response.data) {
          setReturnUrl(response.data)
        }
      })
      .catch(error => {
        setReturnUrl({ "res": "Failed to deploy" })
        setIsSpinner(false)
      });
  }

  async function openModal() {
    setIsOpen(true)
  }

  function onModalClose() {
    setIsOpen(false)
  }

  return (
    <Section
      bg={props.bg}
      textColor={props.textColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container className="text-center">
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={1}
          spaced={true}
        />

        <CodeMirror
          value={codeString}
          options={{
            mode: 'xml',
            theme: 'material',
            lineNumbers: true,
            mode: "javascript"
          }}
          onChange={(editor, data, value) => {
            codeString = value
          }}
          className="code-mirror"
        />

        <Button variant={props.buttonColor} size="lg" onClick={() => onDeployButtonClick()} className="my-3 align-middle">
          {isSpinner ? "Deploying... " : props.buttonText}
          {isSpinner ?
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div> : null}
        </Button>
        {modalIsOpen && returnUrl
          ? <UrlDialogBox url={returnUrl.url} sampleUrl={returnUrl.sample_url} error={returnUrl.res} onClose={onModalClose} /> : null}

      </Container>
    </Section>
  );
}

export default EditorSection;
