import React, { useState } from "react";
import Modal from 'react-modal';
import copy from "copy-to-clipboard";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height: 'auto',
        width: 'auto'
    }
};

function CelebarateIcon() {
    return (<span className="icon">
        <i className="fab fa-trophy" />
    </span>);
}

function UrlDialogBox(props) {

    const urlParams = props.sampleUrl.split("?")
    function copyUrl() {
        copy(props.url);
    }

    function copySampleUrl() {
        copy(props.sampleUrl);
    }

    return (
        <div >
            <Modal
                isOpen={true}
                onRequestClose={props.onClose}
                style={customStyles}
                contentLabel="Example Modal"
                ariaHideApp={false}
            >
                <div className="m-4">
                    <button className="d-flex justify-content-end close" type="button" aria-label="Close" onClick={props.onClose}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <div className="font-weight-bold text-green"><span>Congrats! Your function is successfully deployed.</span>
                        <CelebarateIcon />
                    </div>
                    <hr></hr>
                    <div><small>A sample GET url with your function parameters:</small></div>
                    <div className="d-flex justify-content-between align-middle word-break">
                        <div className=" font-weight-bold p-2 text-primary px-4">{urlParams[0]}
                            <span className=" font-weight-bold text-pink">{urlParams[1]}</span></div>
                        <div className="far fa-copy " onClick={() => copySampleUrl()}></div>
                    </div>
                    <br />
                    <div className="text-secondary font-size-sm ">
                        Note - This URL can be called with both GET and POST methods.
                </div>
                </div>
            </Modal>
        </div>
    );
}

export default UrlDialogBox;