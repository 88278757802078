import React from "react";
import FaqSection from "./../components/FaqSection";
import ContactSection from "./../components/ContactSection";

function FaqPage(props) {
  return (
    <>
      <FaqSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Frequently Asked Questions"
        subtitle=""
      />
      <ContactSection
        bg="white"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us/ Feature Request"
        subtitle=""
        buttonText="Send"
        buttonColor="primary"
        showNameField={true}
      />
    </>
  );
}

export default FaqPage;
