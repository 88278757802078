import React from "react";
import NavbarCustom from "./../components/NavbarCustom";
//import HeroSection from "./../components/HeroSection";
import EditorSection from "./../components/EditorSection";
import FeaturesSection from "./../components/FeaturesSection";
import CtaSection from "./../components/CtaSection";
import NewsletterSection from "./../components/NewsletterSection";
import Footer from "./../components/Footer";

function IndexPage(props) {
  return (
    <>
      <NavbarCustom
        bg="yellow"
        variant="light"
        expand="md"
        logo="logo.png"
      />
      <EditorSection
        bg="yellow"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="Write . Deploy . Call "
        subtitle="Fastest way to create cloud functions. Write your function, deploy and call them from anywhere."
        buttonText="Deploy"
        buttonColor="success"
      />
      <FeaturesSection
        bg="yellow"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        image="features.svg"
      />
      <CtaSection
        bg="yellow"
        textColor="dark"
        size="sm"
        bgImage=""
        bgImageOpacity={1}
        title="Got Questions?"
        subtitle=""
        buttonText="FAQ/Contact"
        buttonColor="primary"
        buttonPath="/faq"
      />
      {/*<NewsletterSection
        bg="whyellowite"
        textColor="dark"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        title="News Letter"
        subtitle="Only update emails no spam"
        buttonText="Subscribe"
        buttonColor="primary"
        inputPlaceholder="Enter your email"
        subscribedMessage="You are now subscribed!"
      /> */}
      <Footer
        bg="dark"
        textColor="white"
        size="md"
        bgImage=""
        bgImageOpacity={1}
        description="A super simple cloud function creator"
        copyright="© 2021 Product"
        logo="logo.png"
      />
    </>
  );
}

export default IndexPage;
